import React, { PropsWithChildren, useState } from 'react';
import { TreeviewItemModel } from '../../components/job/TreeviewNodeModel';
import { CategoriesData, ChannelEndType, Status, Part, Tree, MoldSize, TreeviewFace, TreeviewFaceGroup, Face, ChannelBodyType, BasicMold, Mold, MaterialRootMap, Coordinates } from './job-data';
import CoolingFluid from './cooling-fluid';
import { MoldCategory, MoldMaterialCategory } from './mold-categories';
import MoldMaterial from './mold-material';
import JobData from './job-data';
import CadInfo from './cad-info';
import { findTreeItemByNodeId, getChannelId, getChannelName } from '../../services/TreeviewFunctions';
import { Channel } from './channel';
import { getUniqueObjectArrayByProperty } from '../../utils/arrays.utils';
import { ExtraJobConfig } from '../../components/job/ExtraJobConfiguration';
import { ProjectType } from '../project/project-data';
import { mapPathsToParts } from '../../components/job/helpers/tree-mapper';

export const initialJobContext: JobData = {
    Project: '',
    ProjectType: '',
    JobId: '',
    CustomSolverSettings: "",
    CadInfos: [],
    Categories: new CategoriesData(),
    Tree: {},
    IsTreeLoaded: false,
    Status: Status.None,
    SelectionMode: ChannelBodyType.PARTS,
    contextCleaned: false,
    resizeBasicMold: false,
    basicMold: new BasicMold(),
    expectedCoolingTime: null,
    setProject: () => { },
    setProjectType: () => { },
    setJobId: () => { },
    addToCategory: () => { return true; },
    addToChannels: () => { },
    addBodyToChannel: () => { return true; },
    editChannelBody: () => { },
    addChannelEnd: () => { },
    editChannelEnd: () => { },
    addChannelBaffle: () => { },
    editChannelBaffle: () => { },
    removeChannelBaffle: () => { },
    removeChannelEnd: () => { },
    removeBodyFromChannel: () => { },
    setCategoryParts: () => { },
    removeFromCategory: () => { },
    removeFromChannels: () => { },
    setCategoryMaterial: () => { },
    setCoolingRadius: () => { },
    setCoolingFlow: () => { },
    setCoolingTemperature: () => { },
    setCoolingFluid: () => { },
    setTree: () => { },
    setModelSelection: () => [],
    setVisibility: () => { },
    restoreContext: () => { },
    setMaterialTemperature: () => { },
    setUserEnteredMaterialTemperature: () => { },
    clearContext: () => { },
    setIsTreeLoaded: () => { },
    setStatus: () => { },
    resetChannelsIdAndName: () => { },
    setSelectionMode: () => { },
    SetContextCleaned: () => { },
    setSelectedChannel: () => { },
    setBasicMoldNodesIds: () => {},
    setBasicMoldMaterialIndex: () => {},
    setBasicMoldEnabled: () => {},
    setResizeBasicMold: () => {},
    setBasicMoldSize: () => {},
    setBasicMoldCenter: () => {},
    setFrozenWallThickness: () => {},
    addToMoldGoup: () => {},
    setMoldTemperature: () => {},
    setCustomSolverSettings:() => {},
    setExtraJobConfiguration: (data: ExtraJobConfig) => {}
};

const JobContext = React.createContext<JobData>(initialJobContext);

export const JobContextProvider = (props: PropsWithChildren<{}>) => {

    const setProject = (project: string) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Project: project,
            };
        });
    };

    const setProjectType = (type: string) => {
        const validProjectType = !type ? ProjectType.Design : type;
        setJobDataState(previousState => {
            return {
                ...previousState,
                ProjectType: validProjectType
            };
        });
    };

    const setJobId = (jobId: string) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                JobId: jobId,
            };
        });
    };

    const setProjectUserId = (userId: string) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                ProjectUserId: userId
            };
        });
    };

    const resetChannelsIdAndName = (channels: Channel[]) => {
        let channelsCopy = channels.map((c, channelIndex) => {
            return new Channel({
                ...c.clone(),
                ...{
                    id: getChannelId(channelIndex),
                    name: getChannelName(channelIndex),
                }
            });
        });

        return channelsCopy;
    }

    const removeFromChannels = (channelId: string) => {
        const predicate = (c: Channel) => c.id !== channelId;

        setJobDataState(previousState => {

            const channels = previousState.Categories.Channel.filter(predicate)
            const channelsCopy = resetChannelsIdAndName(channels);

            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Channel: channelsCopy,
                    SelectedChannel: previousState.Categories.SelectedChannel?.id === channelId ? undefined : previousState.Categories.SelectedChannel
                }
            }
        });
    }

    const removeFromCategory = (partId: string, category?: MoldCategory, moldGroupId?: string) => {
        const predicate = (item: Part) => item.id !== partId;

        setJobDataState(previousState => {
            const filteredMoldArray = [...previousState.Categories.Mold];
            if (moldGroupId !== undefined && category == MoldCategory.Mold) {
                let moldGroup = previousState.Categories.Mold.find(m => m.id === moldGroupId);
                let moldGroupIndex = previousState.Categories.Mold?.findIndex(m => m.id == moldGroupId);
                moldGroup = Object.assign({}, moldGroup);
                moldGroup.bodies = [...moldGroup.bodies.filter(predicate) as Mold[]];
                filteredMoldArray[moldGroupIndex] = moldGroup;
            }
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Mold: filteredMoldArray,
                    Part: previousState.Categories.Part.filter(predicate),
                    Channel: previousState.Categories.Channel.filter(predicate),
                    Runner: previousState.Categories.Runner ? previousState.Categories.Runner.filter(predicate) : []
                }
            }
        });
    };

    const removeBodyFromChannel = (channelId: string, bodyId: string) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);

            if (!channel) {
                return {
                    ...previousState
                }
            }

            channel.removeBody(bodyId);

            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Channel: channels,
                    SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                }
            }
        });
    }

    let filterChannels = (selectedParts: Part[], channels: Channel[]): Channel[] => {
        return channels.map(c => {
            selectedParts.forEach(p => c.removeBody(p.id));
            return c.clone();
        });
    }

    const addToCategory = (category: MoldCategory, items: TreeviewFace[], moldGroupId?: string, moldGroupName?: string, validationCb?: (hasError: boolean) => void) => {
        let hasError = false;

        if (category === MoldCategory.Mold && moldGroupId !== undefined) {
            setJobDataState(previousState => {
                validationCb?.(false);

                const parts = mapPathsToParts(items.map(p => p.path), previousState.Tree);
                const uniqueParts = getUniqueObjectArrayByProperty(parts, "id");

                const predicate = (item: Part) => !uniqueParts.find(part => part.id === item.id);
                const filteredMoldArray = previousState.Categories.Mold.map((m) => {
                    return { ...m, bodies: [...m.bodies] };
                })

                let moldGroup = previousState.Categories.Mold.find(m => m.id === moldGroupId);
                if (!moldGroup) {
                    moldGroup = {
                        id: moldGroupId,
                        name: moldGroupName || '',
                        path: '',
                        nodesIds: [],
                        cadFileName: null,
                        bodies: []
                    };
                    filteredMoldArray.push(moldGroup);
                } else {
                    moldGroup = Object.assign({}, moldGroup);
                    filteredMoldArray.forEach((moldgrp, i) => {
                        if (moldGroup && moldGroup.id === moldgrp.id) {
                            filteredMoldArray[i].bodies = [...moldgrp.bodies.filter(predicate), ...uniqueParts as Mold[]]
                        } else {
                            filteredMoldArray[i].bodies = [...moldgrp.bodies.filter(predicate) as Mold[]]
                        }
                    });
                }

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Part: previousState.Categories.Part.filter(predicate),
                        Mold: filteredMoldArray,
                        Runner: previousState.Categories.Runner ? previousState.Categories.Runner.filter(predicate) : [],
                        Channel: filterChannels(uniqueParts, previousState.Categories.Channel)
                    }
                }
            });
        } else {
            setJobDataState(previousState => {
                validationCb?.(false);

                const parts = mapPathsToParts(items.map(p => p.path), previousState.Tree);
                const uniqueParts = getUniqueObjectArrayByProperty(parts, "id");

                const predicate = (item: Part) => !uniqueParts.find(part => part.id === item.id);
                const filteredMoldArray = [...previousState.Categories.Mold];
                previousState.Categories.Mold.forEach((moldgrp, i) => {
                    filteredMoldArray[i].bodies = [...moldgrp.bodies.filter(predicate) as Mold[]];
                })

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Part: category === MoldCategory.Part ? [...previousState.Categories.Part.filter(predicate), ...uniqueParts] : previousState.Categories.Part.filter(predicate),
                        Mold: filteredMoldArray,
                        Runner: category === MoldCategory.Runner && previousState.Categories.Runner ? [...previousState.Categories.Runner.filter(predicate), ...uniqueParts] : previousState.Categories.Runner ? previousState.Categories.Runner.filter(predicate) : [],
                        Channel: filterChannels(uniqueParts, previousState.Categories.Channel)
                    }
                }
            });
        }
        return hasError;
    };

    const addToChannels = (obj: Partial<Channel>[]) => {
        setJobDataState(previousState => {
            const channelId = previousState.Categories.Channel.length;
            const newChannels: Channel[] = [];

            let index = 0;

            for (const o of obj) {
                newChannels.push(new Channel({
                    ...o,
                    id: getChannelId(channelId + index),
                    name: getChannelName(channelId + index),
                }))

                index++;
            }

            if (obj.length === 0) {
                newChannels.push(
                    new Channel({
                        id: getChannelId(channelId + index),
                        name: getChannelName(channelId + index)
                    })
                )
            }

            const channelBodyNodeIds = newChannels.flatMap(c => c.getBodyParts()).flatMap(part => part.nodesIds);
            const predicateForMold = (m: Mold) => { return m.nodesIds?.every(n => channelBodyNodeIds.includes(n) === false) || m.bodies.some(b => b.nodesIds.every(n => channelBodyNodeIds.includes(n) === false)); };
            const partPredicate = (part: Part) => part.nodesIds.every(n => channelBodyNodeIds.includes(n) === false);

            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Mold: previousState.Categories.Mold.filter(predicateForMold),
                    Part: previousState.Categories.Part.filter(partPredicate),
                    Runner: previousState.Categories.Runner?.filter(partPredicate),
                    Channel: [...previousState.Categories.Channel, ...newChannels],
                    SelectedChannel: newChannels.length ? newChannels[newChannels.length - 1].clone() : undefined
                }
            };
        })
    }

    const addBodyToChannel = (channelId: string, item: Part | TreeviewFaceGroup) => {
        let hasErrors = false;

        setJobDataState(previousState => {
            const affectedChannel = previousState.Categories.Channel.find(c => c.id === channelId);

            if (affectedChannel) {
                let part: TreeviewFace[] | Part;

                if (Channel.isPart(item)) {
                    part = item;
                } else {
                    part = item.config;
                }

                const channel = affectedChannel.clone();
                const partNodeIds = Channel.isPart(part) ? part.nodesIds : [];
                const partPredicate = (p: Part) => { return p.nodesIds.every(nodeId => partNodeIds.includes(nodeId) === false) };
                const channelPredicate = (c: Channel) => { return c.id !== affectedChannel.id };

                channel.addBody(part);

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Part: previousState.Categories.Part.filter(partPredicate),
                        Mold: previousState.Categories.Mold.filter(partPredicate),
                        Runner: previousState.Categories.Runner?.filter(partPredicate),
                        Channel: [...previousState.Categories.Channel.filter(channelPredicate), channel],
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }

            } else {
                return previousState;
            }
        });

        return hasErrors;
    }

    const editChannelBody = (channelId: string, bodyId: string, selectedFaces: TreeviewFace[]) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);
            const body = channel?.getBodyFaceGroups().find(b => b.id === bodyId);

            if (channel && body) {
                body.config = selectedFaces;

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    };

    const addChannelEnd = (channelId: string, face: Face, type: ChannelEndType) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);

            const treeItem = findTreeItemByNodeId(face.nodeId, previousState.Tree);

            if (channel && treeItem) {
                channel.addChannelEnd({ ...face, path: treeItem.path }, type);

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    }


    const editChannelEnd = (channelId: string, endId: string, face: Face, type: ChannelEndType) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);
            const terminal = channel?.[type === ChannelEndType.Inlet ? 'inlets' : 'outlets']?.find(t => t.id === endId);

            if (channel && terminal) {
                terminal.face = face;

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    }

    const removeChannelEnd = (channelId: string, endId: string, type: ChannelEndType) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);

            if (channel) {
                channel.removeChannelEnd(endId, type);

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    }

    const addChannelBaffle = (channelId: string, selectedFaces: Part|TreeviewFace[]) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);

            if (channel) {
                channel.addBaffle(selectedFaces);

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    };

    const editChannelBaffle = (channelId: string, baffleId: string, selectedFaces: TreeviewFace[]) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);
            const baffle = channel?.baffles?.filter(Channel.isTreeviewFaceGroup).find(b => b.id === baffleId);

            if (channel && baffle) {
                baffle.config = selectedFaces;

                return { 
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    };

    const removeChannelBaffle = (channelId: string, baffleId: string) => {
        setJobDataState(previousState => {
            const channels = previousState.Categories.Channel.map(c => c.clone());
            const channel = channels.find(c => c.id === channelId);

            if (channel) {
                channel.removeBaffle(baffleId);

                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: channels,
                        SelectedChannel: previousState.Categories.SelectedChannel?.id === channel.id ? channel.clone() : previousState.Categories.SelectedChannel
                    }
                }
            } else {
                return previousState;
            }
        });
    }

    const setCategoryMaterial = (categoryName: string, material: MoldMaterial, moldGroupId: string, moldGroupName?: string) => {
        setJobDataState(previousState => {

            const currentMap: MaterialRootMap = Object.assign({}, previousState.Categories.Materials);

            //Material.id is 0 when selected item in the dropdown is "Select Material" item. So not a material.
            if (material.id == "0") {
                //remove material
                let targetSectionGroupIndex = currentMap[categoryName as keyof MaterialRootMap].findIndex(material => material && material.moldGroupId == moldGroupId);
                if (targetSectionGroupIndex !== null) {
                    currentMap[categoryName as keyof MaterialRootMap].splice(targetSectionGroupIndex, 1);
                }
            } else {
                updateSectionMaterials(categoryName, material, moldGroupId, currentMap);
            }

            //If user select new mold material or basic mold, set mold temperature that comes from plastic part material
            updateMoldTemperatureFromPlasticTemperature(categoryName, material, currentMap);

            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Materials: currentMap
                }
            }
        });
    };

    const updateSectionMaterials = (categoryName: string, material: MoldMaterial, moldGroupId: string, currentMap: MaterialRootMap) => {
        let updatedMaterialForGroup = { ...material, moldGroupId: moldGroupId };
        let targetSectionGroupIndex = currentMap[categoryName as keyof MaterialRootMap].findIndex(material => material && material.moldGroupId == moldGroupId);

        //Set material/update
        if (targetSectionGroupIndex >= 0) {
            //material for target groups needs to be updated
            currentMap[categoryName as keyof MaterialRootMap][targetSectionGroupIndex] = updatedMaterialForGroup;
        } else {
            //material doesnt exist for target section, add it
            currentMap[categoryName as keyof MaterialRootMap].push(updatedMaterialForGroup);
        }

        if (categoryName === MoldMaterialCategory.Molds || categoryName === MoldMaterialCategory.BasicMold) {
            //update basic mold if same material
            const otherBasicMoldSectionWithSameMaterial = currentMap[MoldMaterialCategory.BasicMold]?.find(mgMat => { return mgMat.moldGroupId && mgMat.id == material.id });
            if (otherBasicMoldSectionWithSameMaterial) {
                let updatedMaterialForOtherGroup = { ...material, moldGroupId: otherBasicMoldSectionWithSameMaterial.moldGroupId };
                const index = currentMap[MoldMaterialCategory.BasicMold].findIndex(m => m.moldGroupId == otherBasicMoldSectionWithSameMaterial.moldGroupId);
                currentMap[MoldMaterialCategory.BasicMold][index] = updatedMaterialForOtherGroup;
            }

            //Update material values for other mold sections with same material
            const otherMoldSectionsWithSameMaterial = currentMap[MoldMaterialCategory.Molds]?.filter(mgMat => { return mgMat.moldGroupId && mgMat.id == material.id });
            for (const otherMoldSection of otherMoldSectionsWithSameMaterial) {
                let updatedMaterialForOtherGroup = { ...material, moldGroupId: otherMoldSection.moldGroupId };
                const index = currentMap[MoldMaterialCategory.Molds].findIndex(m => m.moldGroupId == otherMoldSection.moldGroupId);
                currentMap[MoldMaterialCategory.Molds][index] = updatedMaterialForOtherGroup;
            }
        }
    }

    const updateMoldTemperatureFromPlasticTemperature = (categoryName: string, material: MoldMaterial, currentMap: MaterialRootMap) => {
        if (categoryName === MoldMaterialCategory.Molds || categoryName === MoldMaterialCategory.BasicMold) {
            //set current selected part moldTemperature
            const moldTemperature = currentMap[MoldMaterialCategory.Parts][0]?.moldTemperature ?? material.temperature ?? 0;
            currentMap[categoryName].forEach(c => {
                c.temperature = moldTemperature;
            });
        }
        if (categoryName === MoldMaterialCategory.Parts) {
            //set current selected part moldTemperature
            const moldTemperature = currentMap[MoldMaterialCategory.Parts][0]?.moldTemperature ?? material.temperature ?? 0;
            currentMap[MoldMaterialCategory.BasicMold]?.forEach(c => {
                c.temperature = moldTemperature;
            });
            currentMap[MoldMaterialCategory.Molds]?.forEach(c => {
                c.temperature = moldTemperature;
            });
        }
    }

    const SetContextCleaned = (isContextCleaned: boolean) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                contextCleaned: isContextCleaned
            }
        });
    }

    const setCoolingRadius = (radius: number) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Cooling: {
                        ...previousState.Categories.Cooling,
                        radius: radius
                    }
                }
            }
        });
    };

    const setCoolingFlow = (flow: number) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Cooling: {
                        ...previousState.Categories.Cooling,
                        flow: flow
                    }
                }
            }
        });
    };

    const setCoolingTemperature = (temperature: number) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Cooling: {
                        ...previousState.Categories.Cooling,
                        temperature: temperature
                    }
                }
            }
        });
    };

    const setUserEnteredMaterialTemperature = (categoryName: string, temperature: number, moldGroupId: string) => {
        setJobDataState(previousState => {
            const currentMap: MaterialRootMap = Object.assign({}, previousState.Categories.Materials);
            const moldIndex = previousState.Categories.Materials[categoryName as keyof MaterialRootMap].findIndex(m => m.moldGroupId === moldGroupId);

            if (categoryName === MoldMaterialCategory.Molds) {
                Object.keys(previousState.Categories.Materials[categoryName]).forEach((key, index) => {
                    if (index === moldIndex) {
                        currentMap.Molds[index] = { ...previousState.Categories.Materials[categoryName][moldIndex] };
                        currentMap.Molds[index].userEnteredMaterialTemperature = true;
                        currentMap.Molds[index].temperature = temperature;
                    } else {
                        currentMap.Molds[index] = previousState.Categories.Materials[categoryName][index];
                    }
                });
            } else if (categoryName === MoldMaterialCategory.Parts) {
                Object.keys(previousState.Categories.Materials[categoryName]).forEach((key, index) => {
                    if (index === moldIndex) {
                        currentMap.Parts[index] = { ...previousState.Categories.Materials[categoryName][moldIndex] };
                        currentMap.Parts[index].userEnteredMaterialTemperature = true;
                        currentMap.Parts[index].temperature = temperature;
                    } else {
                        currentMap.Parts[index] = previousState.Categories.Materials[categoryName][index];
                    }
                });
            } else {
                currentMap[categoryName as keyof MaterialRootMap] = [];
            }


            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Materials: currentMap
                }
            }
        });
    }

    const setMaterialTemperature = (categoryName: string, temperature: number, moldGroupId: string) => {
        setJobDataState(previousState => {
            const currentMap: MaterialRootMap = Object.assign({}, previousState.Categories.Materials);
            const moldIndex = previousState.Categories.Materials[categoryName as keyof MaterialRootMap].findIndex(m => m.moldGroupId === moldGroupId);

            if (categoryName === MoldMaterialCategory.Molds) {
                Object.keys(previousState.Categories.Materials[categoryName]).forEach((key, index) => {
                    currentMap.Molds[index] = { ...previousState.Categories.Materials[categoryName][index] };
                    currentMap.Molds[index].temperature = temperature;
                });
            } else if (categoryName === MoldMaterialCategory.Parts) {
                Object.keys(previousState.Categories.Materials[categoryName]).forEach((key, index) => {
                    if (index === moldIndex) {
                        currentMap.Parts[index] = { ...previousState.Categories.Materials[categoryName][moldIndex] };
                        currentMap.Parts[index].temperature = temperature;
                    } else {
                        currentMap.Parts[index] = previousState.Categories.Materials[categoryName][index];
                    }
                });
            }


            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Materials: currentMap
                }
            }
        });
    };

    const setCoolingFluid = (fluid: CoolingFluid) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Cooling: {
                        ...previousState.Categories.Cooling,
                        fluid: fluid
                    }
                }
            }
        });
    };

    const setTree = (treeItems: { [id: string]: TreeviewItemModel }) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Tree: treeItems
            }
        });
    };

    // This function is duct taped hell. Needed when you click a grouped node in the 3D model to select the sibling nodes. 
    // We need to think of an efficient way to manage selection / updates. 
    // I added a bunch of junk checks to workaround weird behavior / extra callbacks from the HOOPS SDK. 
    const setModelSelection = (selected: Face[]) => {

        setJobDataState(previousState => {
            const treeItems = Object.values(previousState.Tree);
            const visibleNodes = new Map(selected.map(s => [s.nodeId, true]));
            let groupMembersToSelect: Set<number> = new Set();
            let selectedTreeviewFaces: TreeviewFace[] = [];

            const shouldBeSelected = (nodeId: number) => visibleNodes.has(nodeId);

            treeItems.forEach(treeItem => treeItem.isSelected = treeItem.nodeIds.some(shouldBeSelected));

            for (const face of selected) {
                const faceTreeItems = treeItems.filter(i => i.nodeIds.includes(face.nodeId));

                faceTreeItems.forEach(fti => {
                    selectedTreeviewFaces.push({
                        nodeId: face.nodeId,
                        faceIndex: face.faceIndex,
                        path: fti.path
                    });

                    fti.nodeIds.filter(nodeId => nodeId !== face.nodeId).forEach(nodeId => groupMembersToSelect.add(nodeId));
                })

            }

            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Selected: selectedTreeviewFaces
                },
                Tree: {
                    ...previousState.Tree
                }
            }
        })

    };

    const setVisibility = (partIds: string[], visibility: boolean) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Tree: {
                    ...previousState.Tree,
                    ...Object.assign({}, ...partIds.map(id => ({ [id]: { ...previousState.Tree[id], isVisible: visibility } })))
                }
            };
        });
    };

    const restoreContext = (project: string, jobId: string, categories: CategoriesData, cadInfos: Array<CadInfo>, status: Status, expectedCoolingTime: number | null, customSolverSettings: string|null, basicMold: BasicMold = new BasicMold()) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Project: project,
                JobId: jobId,
                CadInfos: cadInfos,
                Categories: categories,
                Status: status,
                expectedCoolingTime: expectedCoolingTime,
                CustomSolverSettings: customSolverSettings,
                basicMold: basicMold ?? new BasicMold(),
                Tree: {}
            }
        });
    };

    const clearContext = () => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Project: "",
                CadInfos: [],
                Categories: new CategoriesData(),
                Tree: {},
                Status: Status.None,
                IsTreeLoaded: false,
                contextCleaned: false,
                expectedCoolingTime: null,
                basicMold: new BasicMold(),
                resizeBasicMold: false
            }
        });
    };

    const setIsTreeLoaded = (isTreeLoaded: boolean) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                IsTreeLoaded: isTreeLoaded
            };
        });
    };

    const setCategoryParts = (category: MoldCategory, parts: Part[]) => {
        if (category === MoldCategory.Part) {
            setJobDataState(previousState => {
                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Part: parts
                    }
                };
            });
        }

        if (category === MoldCategory.Runner) {
            setJobDataState(previousState => {
                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Runner: parts
                    }
                };
            });
        }

        if (category === MoldCategory.Channel) {
            setJobDataState(previousState => {
                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Channel: parts as Channel[],
                        SelectedChannel: (parts.length > 0 ? parts[0] as Channel : undefined)
                    }
                };
            });
        }

        if (category === MoldCategory.Mold) {
            setJobDataState(previousState => {
                return {
                    ...previousState,
                    Categories: {
                        ...previousState.Categories,
                        Mold: parts as Mold[]
                    }
                };
            });
        }
    };

    const setStatus = (status: Status) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Status: status
            };
        });
    };

    const setSelectionMode = (mode: ChannelBodyType) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                SelectionMode: mode
            }
        });
    }

    const setSelectedChannel = (channel: Channel | undefined) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    SelectedChannel: channel
                }
            }
        });
    };

    const setBasicMoldNodesIds = (nodesIds: number[]) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                basicMold: {
                    ...previousState.basicMold,
                    nodesIds
                }
            }
        });
    };

    const setBasicMoldEnabled = (enabled: boolean) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                basicMold: {
                    ...previousState.basicMold,
                    enabled
                }
            }
        });
    };

    const setResizeBasicMold = (value: boolean) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                resizeBasicMold: value
            }
        });
    };

    const setCustomSolverSettings = (value: string) => {
          setJobDataState(previousState => {
             return {
                ...previousState,
                CustomSolverSettings: value
            }
        });
    }

    const setBasicMoldSize = (size: MoldSize) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                basicMold: {
                    ...previousState.basicMold,
                    size
                }
            }
        });
    };

    const setBasicMoldCenter = (center: Coordinates) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                basicMold: {
                    ...previousState.basicMold,
                    center
                }
            }
        });
    };

    const setFrozenWallThickness = (wallThickness: number) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                Categories: {
                    ...previousState.Categories,
                    Cooling: {
                        ...previousState.Categories.Cooling,
                        frozenWallThickness: wallThickness
                    }
                }
            }
        });
    };

    const addToMoldGoup = (moldGroupId: number, moldGroupName: string) => {
        setJobDataState(previousState => {
            return {
                ...previousState,

            }
        });
    };

    const setMoldTemperature = (moldTemperature: number) => {
        setJobDataState(previousState => {
            //set moldTemperature for plastic material
            const plasticMatCopy = { ...previousState.Categories.Materials.Parts[0] };
            if (!plasticMatCopy) return { ...previousState };

            plasticMatCopy.moldTemperature = moldTemperature;

            //set moldTemperature for all mold groups material
            const moldGroupsMaterialCopy = previousState.Categories.Materials.Molds.map((mgMat) => {
                return { ...mgMat }
            });
            moldGroupsMaterialCopy.forEach((mgMat) => {
                mgMat.temperature = moldTemperature;
            })

            //set for basic mold if exists
            const basicMoldGroupMaterialCopy = previousState.Categories.Materials.BasicMold.length > 0 ? { ...previousState.Categories.Materials.BasicMold[0] } : null;
            if (basicMoldGroupMaterialCopy)
                basicMoldGroupMaterialCopy.temperature = moldTemperature;

            //create new state
            const newState = { ...previousState };
            newState.Categories.Materials.Parts[0] = plasticMatCopy
            newState.Categories.Materials.Molds = moldGroupsMaterialCopy

            if (basicMoldGroupMaterialCopy)
                newState.Categories.Materials.BasicMold[0] = basicMoldGroupMaterialCopy

            return newState;
        });
    }

    const setExtraJobConfiguration = (data: ExtraJobConfig) => {
        setJobDataState(previousState => {
            return {
                ...previousState,
                expectedCoolingTime: data.expectedCoolingTime
            }
        });
    };

    const [jobState, setJobDataState] = useState<JobData>(
        {
            ...initialJobContext,
            setStatus: setStatus,
            setProject: setProject,
            setProjectType: setProjectType,
            setJobId: setJobId,
            addToCategory: addToCategory,
            addToChannels: addToChannels,
            addBodyToChannel: addBodyToChannel,
            editChannelBody: editChannelBody,
            addChannelEnd: addChannelEnd,
            addChannelBaffle: addChannelBaffle,
            editChannelEnd: editChannelEnd,
            editChannelBaffle: editChannelBaffle,
            removeChannelBaffle: removeChannelBaffle,
            removeChannelEnd: removeChannelEnd,
            removeBodyFromChannel: removeBodyFromChannel,
            resetChannelsIdAndName: resetChannelsIdAndName,
            setCategoryParts: setCategoryParts,
            removeFromCategory: removeFromCategory,
            setCategoryMaterial: setCategoryMaterial,
            setCoolingRadius: setCoolingRadius,
            setCoolingFlow: setCoolingFlow,
            setCoolingTemperature: setCoolingTemperature,
            setCoolingFluid: setCoolingFluid,
            setTree: setTree,
            setModelSelection: setModelSelection,
            setVisibility: setVisibility,
            restoreContext: restoreContext,
            setMaterialTemperature: setMaterialTemperature,
            setUserEnteredMaterialTemperature: setUserEnteredMaterialTemperature,
            clearContext: clearContext,
            setIsTreeLoaded: setIsTreeLoaded,
            removeFromChannels: removeFromChannels,
            setSelectionMode: setSelectionMode,
            SetContextCleaned: SetContextCleaned,
            setSelectedChannel: setSelectedChannel,
            setBasicMoldNodesIds: setBasicMoldNodesIds,
            setBasicMoldEnabled: setBasicMoldEnabled,
            setResizeBasicMold: setResizeBasicMold,
            setBasicMoldSize: setBasicMoldSize,
            setCustomSolverSettings: setCustomSolverSettings,
            setBasicMoldCenter: setBasicMoldCenter,
            setFrozenWallThickness: setFrozenWallThickness,
            addToMoldGoup: addToMoldGoup,
            setMoldTemperature: setMoldTemperature,
            setExtraJobConfiguration: setExtraJobConfiguration
        }
    );

    return (
        <JobContext.Provider value={jobState}>
            {props.children}
        </JobContext.Provider>
    );
};

export default JobContext;

const humanReadableReplacer = (key: string, value: any) => {
    //TODO. consider removing part id, seems irrelevant with path
    if (key === 'Selected' || key === 'Tree' || key === 'contextCleaned' || key === 'IsTreeLoaded' || key === 'SelectedChannel') {
        return;
    }

    if (value instanceof Map) {
        return Array.from(value.entries());
    } else {
        return value;
    }
};

export const serializeJobContextState = (jobContext: any) => {
    return JSON.stringify(jobContext, humanReadableReplacer);
}