import { Coordinates, MoldSize, Part } from "../store/job/job-data";
import { moldGroup1Color } from "../store/uiSettings/communicatorColors";

// A data type to distinguish between two types of numeric IDs used for HOOPS
// assembly tree nodes.
//
// The model ID is stable (always refers to the same entity) and should be used
// for persistence.
//
// This ID is unique within a given CAD model, but will not be unique within a
// viewer session when more than one CAD model is loaded.
//
// This type is freely convertible to and from number, but not implicitly
// convertible to SessionNodeId to prevent mistakes.
export const enum ModelNodeId {
}

// A data type to distinguish between two types of numeric IDs used for HOOPS
// assembly tree nodes.
//
// The session ID of a node is generated at runtime and depends on the load
// order of the CAD models. Therefore, the session ID is not stable and should
// never be used for persistence.
//
// This ID is unique within a given viewer session. Note that IDs are recycled:
// an expired ID can be re-used to identify different nodes after models are
// unloaded.
//
// This is the kind of ID expected by most functions in the viewer API.
//
// This type is freely convertible to and from number, but not implicitly
// convertible to ModelNodeId to prevent mistakes.
export const enum SessionNodeId {
}

let markupIds: string[] = []

class DebugMarkupItem extends Communicator.Markup.MarkupItem {
    private _hwv: Communicator.WebViewer;

    private _isFinalized: boolean = false;

    private _point: Communicator.Point3;

    private _text: string;

    private _color: Communicator.Color;

    constructor(hwv: Communicator.WebViewer, point: Communicator.Point3, text: string, color: Communicator.Color = Communicator.Color.green()) {
        super();
        this._hwv = hwv;
        this._point = point;
        this._color = color;
        this._isFinalized = false;
        this._text = text;
    }

    draw() {
        let point3d = this._hwv.view.projectPoint(this._point);
        let point2d = Communicator.Point2.fromPoint3(point3d);

        let text = new Communicator.Markup.Shape.Text(this._text, point2d);
        let bullet = new Communicator.Markup.Shape.Circle()
        bullet.setCenter(point2d);
        bullet.setFillColor(this._color);
        bullet.setRadius(5);


        this._hwv.markupManager.getRenderer().drawText(text);
        this._hwv.markupManager.getRenderer().drawCircle(bullet);
    }

    finalize() {
        this._isFinalized = true;
        this._hwv.markupManager.refreshMarkup();
    }
}

class DebugLineMarkupItem extends Communicator.Markup.MarkupItem {
    private _hwv: Communicator.WebViewer;

    private _isFinalized: boolean = false;

    private _startPoint: Communicator.Point3;

    private _endPoint: Communicator.Point3;

    private _color: Communicator.Color;

    constructor(hwv: Communicator.WebViewer, startPoint: Communicator.Point3, endPoint: Communicator.Point3, color: Communicator.Color = Communicator.Color.green()) {
        super();
        this._hwv = hwv;
        this._startPoint = startPoint;
        this._endPoint = endPoint;
        this._color = color;
        this._isFinalized = false;
    }

    draw() {
        const startPoint = this._hwv.view.projectPoint(this._startPoint);
        const startPoint2d = Communicator.Point2.fromPoint3(startPoint);

        const endPoint = this._hwv.view.projectPoint(this._endPoint);
        const endPoint2d = Communicator.Point2.fromPoint3(endPoint);

        const line = new Communicator.Markup.Shape.Line(startPoint2d, endPoint2d);
        line.setStrokeColor(this._color);

        this._hwv.markupManager.getRenderer().drawLine(line);
    }

    finalize() {
        this._isFinalized = true;
        this._hwv.markupManager.refreshMarkup();
    }
}

class FaceInfo {
    nodeId: number;

    faceIndex: number;

    vertices: Map<string, Communicator.Point3>;

    normal: Communicator.Point3;

    props: Communicator.SubentityProperties.Face;

    get type(): Communicator.SubentityProperties.FaceType {
        return this.props.type();
    }

    get key(): string {
        return `${this.nodeId}-${this.faceIndex}`;
    }

    constructor(nodeId: number, faceIndex: number, vertices: Communicator.Point3[], normal: Communicator.Point3, props: Communicator.SubentityProperties.Face) {
        this.nodeId = nodeId;
        this.faceIndex = faceIndex;
        this.normal = normal;
        this.props = props;

        this.vertices = new Map<string, Communicator.Point3>();
        vertices.forEach(v => {
            this.vertices.set(`${v.x}-${v.y}-${v.z}`, v);
        })
    }

    public static isEqual(face1: FaceInfo, face2: FaceInfo): boolean {
        return face1.key === face2.key;
    }

    public static areConnected(face1: FaceInfo, face2: FaceInfo): boolean {
        for (const key1 of face1.vertices.keys()) {
            if (face2.vertices.has(key1)) {
                return true;
            }
        }

        return false;
    }
}

function buildConnectionMap(faces: FaceInfo[]): Map<FaceInfo, FaceInfo[]> {
    const connectionMap: Map<FaceInfo, FaceInfo[]> = new Map();

    faces.forEach(currentFace => {
        for (const f of faces) {
            if (FaceInfo.isEqual(f, currentFace) === false && FaceInfo.areConnected(f, currentFace)) {
                if (connectionMap.has(currentFace)) {
                    connectionMap.get(currentFace)!.push(f);
                } else {
                    connectionMap.set(currentFace, [f]);
                }
            }
        }
    });

    return connectionMap;
}

function getBaffleFaces(startFace: FaceInfo, restrictedFaces: FaceInfo[], connectionMap: Map<FaceInfo, FaceInfo[]>): FaceInfo[] {
    const result: FaceInfo[] = [];
    let stack = [startFace];

    while (stack.length) {
        const currentFace = stack.pop()!;

        if (result.includes(currentFace) === false) {
            result.push(currentFace);
        }

        const connectedFaces = (connectionMap.get(currentFace) ?? []).filter(f => restrictedFaces.includes(f) === false
            && result.includes(f) === false);
        stack = [...stack, ...connectedFaces];
    }

    return result;
}

async function detectBaffles(faces: FaceInfo[]): Promise<Face[][]> {
    const connectionMap = buildConnectionMap(faces);

    const cylinderFaces = faces.filter(f => f.type === Communicator.SubentityProperties.FaceType.Cylinder);

    const baffles: Face[][] = [];

    for (const baffleBody of cylinderFaces) {
        const baffleBodyConnectedFaces = connectionMap.get(baffleBody) ?? [];

        const isOrthogonal = (f: FaceInfo): boolean => {
            const angle = Communicator.Util.computeAngleBetweenVector(f.normal, baffleBody.normal);
            return f.type === Communicator.SubentityProperties.FaceType.Cylinder && (angle > 80 && angle < 110)
        }

        const connectedOnlyToBaffleBody = (f: FaceInfo): boolean => {
            const potentialChannelConnectedFaces = connectionMap.get(f) ?? [];
            return potentialChannelConnectedFaces.every(pccf => baffleBodyConnectedFaces.includes(pccf) === false);
        }

        const hasSmallerRadius = (f: FaceInfo): boolean => {
            return (baffleBody.props as Communicator.SubentityProperties.CylinderElement).radius > (f.props as Communicator.SubentityProperties.CylinderElement).radius * 1.05;
        }

        const potentialChannels = baffleBodyConnectedFaces.filter(face => face.normal !== undefined).filter(isOrthogonal).filter(connectedOnlyToBaffleBody);

        const allPotentialChannelsHaveSmallerRadius = potentialChannels.every(hasSmallerRadius);

        if (allPotentialChannelsHaveSmallerRadius && potentialChannels.length > 1 && baffleBodyConnectedFaces.length > potentialChannels.length) {

            const baffle: Face[] = getBaffleFaces(baffleBody, potentialChannels, connectionMap).map(b => {
                return {
                    faceIndex: b.faceIndex,
                    nodeId: b.nodeId
                }
            });
            baffles.push(baffle);
        }
    }

    return baffles;
}

async function getNodeCenterTranlsation(nodeId: number, hwv: Communicator.WebViewer) {
    const initialNodeBounding = await hwv.model.getNodesBounding([nodeId]);
    const currentNodeBounding = await hwv.model.getNodeRealBounding(nodeId);
    const initialCenter = initialNodeBounding.center();
    const currentCenter = currentNodeBounding.center();

    return new Communicator.Point3(
        currentCenter.x - initialCenter.x,
        currentCenter.y - initialCenter.y,
        currentCenter.z - initialCenter.z
    )
}

async function populateFaceInfoFromNodeId(nodeId: number, hwv: Communicator.WebViewer): Promise<FaceInfo[]> {
    const meshData = await hwv.model.getNodeMeshData(nodeId);
    const faceCount = await hwv.model.getFaceCount(nodeId);
    const result: FaceInfo[] = [];

    for (let i = 0; i < faceCount; i++) {
        const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(nodeId, i);

        if (props) {
            result.push(new FaceInfo(nodeId, i, await getFaceVertices({ nodeId, faceIndex: i }, meshData), (props as any).normal, props));
        }
    }

    return result;
}

async function populateFaceInfoFromFaces(faces: Face[], hwv: Communicator.WebViewer): Promise<FaceInfo[]> {
    const result: FaceInfo[] = [];
    const meshDataCache = new Map<number, Communicator.MeshDataCopy>();

    for (const face of faces) {
        const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(face.nodeId, face.faceIndex);

        if (props) {
            let meshData = meshDataCache.get(face.nodeId);

            if (!meshData) {
                meshData = await hwv.model.getNodeMeshData(face.nodeId);
                meshDataCache.set(face.nodeId, meshData);
            }

            result.push(new FaceInfo(face.nodeId, face.faceIndex, await getFaceVertices(face, meshData), (props as any).normal, props));
        }
    }

    return result;
}

function isPointOnPlane(point: Communicator.Point3, plane: Communicator.Plane): boolean {
    const distance = plane.distanceToPoint(point);
    return Math.round(distance) === 0;
}

export function getBoundingBox(points: Communicator.Point3[]): Communicator.Box {
    const box = new Communicator.Box(points[0], points[1]);
    points.forEach(v => box.addPoint(v));

    return box;
}

function getUniquePoints(points: Communicator.Point3[]): Communicator.Point3[] {
    return [...new Set(points.map(makePointKey)).values()].map(parsePointKey)
}

export class VertexGroup {
    public readonly nodeId: number;

    public edges: Edge[];

    constructor(nodeId: number, edges: Edge[]) {
        this.nodeId = nodeId;
        this.edges = edges;
    }

    get key(): string {
        return this.edges.map(e => e.key).join('+');
    }

    get vertices(): Communicator.Point3[] {
        return this.edges.flatMap(e => e.vertices);
    }

    get bb(): Communicator.Box {
        return getBoundingBox(this.vertices);
    }

    get plane(): Communicator.Plane {
        const av = arrangeVertices(this.vertices);
        return Communicator.Plane.createFromPoints(av[0], av[1], av[2]);
    }

    addEdge(edge: Edge) {
        this.edges = [...this.edges, edge];
    }

    clone(): VertexGroup {
        return new VertexGroup(this.nodeId, [...this.edges]);
    }

    isEdgeConnected(edge: Edge, hwv: Communicator.WebViewer): boolean {
        return this.vertices.some(v => edge.hasVertex(v));
    }

    static areConnected(v1: VertexGroup, v2: VertexGroup): boolean {
        const edge1Vkeys = new Set<string>(v1.vertices.map(makePointKey));
        const edge2Vkeys = new Set<string>(v2.vertices.map(makePointKey));
        let sharedVerticeCount = 0;

        edge1Vkeys.forEach(v1k => {
            if (edge2Vkeys.has(v1k)) {
                sharedVerticeCount++;
            }
        })

        return sharedVerticeCount > 1;
    }
}

export class Edge {
    public readonly nodeId: number;

    public readonly edgeIndex: number;

    public readonly vertices: Communicator.Point3[];

    private props: Communicator.SubentityProperties.Edge | null = null;

    constructor(nodeId: number, edgeIndex: number, vertices: Communicator.Point3[]) {
        this.nodeId = nodeId;
        this.edgeIndex = edgeIndex;
        this.vertices = vertices;
    }

    get key() {
        return `${this.nodeId}-${this.edgeIndex}`;
    }

    get bb(): Communicator.Box {
        return getBoundingBox(this.vertices);
    }

    setProps(p: Communicator.SubentityProperties.Edge | null) {
        this.props = p;
    }

    hasVertex(vertex: Communicator.Point3): boolean {
        return this.vertices.length > 0 && this.vertices.some(v => v.equals(vertex));
    }

    minDistance(refPoint: Communicator.Point3): number {
        return this.vertices.map(v => ({ ...v, d: Communicator.Point3.distance(v, refPoint) })).sort((v1, v2) => v1.d - v2.d)[0].d;
    }

    isLine(): boolean {
        const isNarrow = (): boolean => {
            const bb = getPlaneAreaBox(this.vertices);

            if (bb) {
                const extents = bb.extents();
                return [extents.x, extents.y, extents.z].filter(coord => coord < 2).length > 1;
            }

            return false;
        }


        if (this.props?.type() === Communicator.SubentityProperties.EdgeType.Line) {
            return true;
        } else {
            return isNarrow();
        }
    }

    public static sameSpace(edge1: Edge, edge2: Edge): boolean {
        const bb1 = getBoundingBox(edge1.vertices);
        const bb2 = getBoundingBox(edge2.vertices);

        return bb1.extents().equals(bb2.extents());
    }

    public static isEqual(edge1: Edge, edge2: Edge): boolean {
        return edge1.nodeId === edge2.nodeId && edge1.edgeIndex === edge2.edgeIndex;
    }

    public static distance(edge1: Edge, edge2: Edge): number {
        const bb1 = getBoundingBox(edge1.vertices);
        const bb2 = getBoundingBox(edge2.vertices);

        return Communicator.Point3.distance(bb1.center(), bb2.center());
    }

    public static areOnSamePlane(edge1: Edge | VertexGroup, edge2: Edge | VertexGroup): boolean {
        const allVertices = getUniquePoints([...edge1.vertices, ...edge2.vertices]);
        const bb = getBoundingBox(allVertices);
        const plane = Communicator.Plane.createFromPoints(bb.center(), allVertices[0], allVertices[1]);


        return allVertices.every(v => isPointOnPlane(v, plane));
    }

    public static areConnected(edge1: Edge | VertexGroup, edge2: Edge | VertexGroup): boolean {
        const edge1Vkeys = new Set<string>(edge1.vertices.map(makePointKey));
        const edge2Vkeys = new Set<string>(edge2.vertices.map(makePointKey));
        const diff = Communicator.Util.setSubtraction(edge1Vkeys, edge2Vkeys);

        return diff.size !== edge1Vkeys.size;
    }
}

export type Face = { nodeId: number, faceIndex: number };

export type GetColorFn = (nodeId: number, faceIndex: number) => Promise<Communicator.Color | null>

//
// Selection
//

function createSelectionItemsFromNodeIds(nodeIds: Set<SessionNodeId>) {
    let selectionItemList = [];
    for (const selectedId of nodeIds) {
        const selectionItem = Communicator.Selection.SelectionItem.create(selectedId);
        selectionItemList.push(selectionItem);
    }
    return selectionItemList;
}

function getCurrentSelectionNodeIds(hwv: Communicator.WebViewer) {
    const currentSelectionIds = new Set<number>();
    hwv.selectionManager.each(function (selectionItem) {
        currentSelectionIds.add(selectionItem.getNodeId());
    });
    return currentSelectionIds;
}

function setDifference(a: Set<SessionNodeId>, b: Set<SessionNodeId>) {
    return new Set([...a].filter(x => !b.has(x)))
}

// Specifies how node selections are canonicalized.
//
// |- A
// |- B
// |  |- C        <- Select
// |  |- D        <- Select
// |     |- E     <- Select
// |     |- F
// |- G
export enum SelectionValidationMode {
    // In case of redundant selection, keep the dominator for each branch.
    // Results in the fewest modifications.
    // (C, D, E) becomes (C, D).
    Dominators,
    // Same as the previous method, but automatically ascend to the parent
    // when all siblings are selected. Produces the shallowest tree.
    // (C, D, E) becomes (B).
    DominatorsAscend,
    // Select related leaf nodes to avoid any chance of conflict.
    // (C, D, E) becomes (E, F).
    Leaves
}

export function selectNodeIds(selectionIds: SessionNodeId[], hwv: Communicator.WebViewer, mode: SelectionValidationMode = SelectionValidationMode.Dominators) {
    // Update the state of the selectionManager, but first canonicalize the selection.
    //
    // Selections are not allowed to contain more than one node on any given branch. That is,
    // it is forbidden to select both a node and one of its ancestor or descendant. If such selection is
    // made, HOOPS selection manager will automatically modify the selection to ensure its
    // validity. However, this causes the callbacks to be re-entered many times. This situation
    // can be avoid by pruning the selection ourself from the start.
    //
    // There are a few ways to go about it. For a given unprocessed selection, either use only
    // the related leaf nodes (therefore, no ancestors will be selected), or select the dominator
    // nodes (therefore, no descendants will be selected). We opt for the latter by default,
    // because it produces a shallow tree with few nodes. It also keeps model tree expansion to the minimum.
    // Otherwise, all methods are semantically equivalent.

    let targetSelectionIds;
    switch (mode) {
        case SelectionValidationMode.Dominators:
            targetSelectionIds = getAllDominatorNodeIds(selectionIds, hwv.model, false);
            break;
        case SelectionValidationMode.DominatorsAscend:
            targetSelectionIds = getAllDominatorNodeIds(selectionIds, hwv.model, true);
            break;
        case SelectionValidationMode.Leaves:
            targetSelectionIds = getAllLeafNodeIds(selectionIds, hwv.model);
            break;
    }
    
    selectNodeIdsUnvalidated(new Set(targetSelectionIds), hwv);
}

function selectNodeIdsUnvalidated(targetSelectionIds: Set<SessionNodeId>, hwv: Communicator.WebViewer) {
    // Update the state of the selectionManager such that all target nodes become selected.
    //
    // The function is called unvalidated because it does not verify the viewer preconditions
    // (can only have one node selected per branch of the model tree). Beware that if an invalid
    // selection is submitted:
    // - the viewer will automatically modify the selection to ensure its validity;
    // - this will consume multiple update cycles;
    // - it may never converge (not usually a problem unless the application stubbornly attempt
    //   to undo the viewer changes);
    // - the selection will settle but not honor the target selection exactly.
    //
    // The implementation is a lot more convoluted that it should be. Ideally,
    // the function should just remove unwanted nodes, in bulk, then add missing
    // nodes, in bulk. Or, clear all nodes, then add all wanted nodes at once.
    //
    // On one hand, we want to perform selections in bulk, many nodes at a time
    // instead of one node at a time. Every time the selection is modified,
    // updates are propagated through the system. For large selections, this
    // causes visible delays. Therefore, we want to minimize the number of updates.
    //
    // On the other hand, UI refresh is buggy in the presence of bulk selection
    // (as detailed below).
    //
    // ---
    //
    // Explanation of the buggy behavior as of HOOPS v23.0.0. These observations
    // are made with the (now deprecated) legacy model tree UI (web_viewer_ui.js),
    // based on jquery. To be revisited when our version of HOOPS Communicator
    // is updated, or if we switch to a modern model tree alternative.
    //
    // The selectionManager add and remove functions accept either a single
    // value or an array argument.
    //
    // The version accepting a single value triggers the usual callbacks after
    // every change, which is intercepted by the UI to highlight nodes in the
    // model tree. This works well, but is inefficient for multiple selections.
    //
    // When the array version is invoked, the viewer enters "batch" mode (aka
    // "incrementalSelectionBatchBegin"). While in batch mode, the usual triggers
    // are generated, but processing of UI updates is suppressed. When the viewer
    // leaves batch mode, the model tree UI is not synced with the selection state
    // ("incrementalSelectionBatchEnd" should force the UI to synchronize but doesn't).
    //
    // Therefore, we make sure to always issue a (possibly dummy) add/remove for
    // a single item to force the UI to refresh.
    const currentSelectionIds = getCurrentSelectionNodeIds(hwv);
    
    const idsToAdd = setDifference(targetSelectionIds, currentSelectionIds);
    const idsToRem = setDifference(currentSelectionIds, targetSelectionIds);

    // Remove unwanted items from the current selection.
    //
    // This could happen if a node that initiated the selection progress (thus
    // is added first to the selection set) is not wanted in the final target
    // set. For instance, if a component node was selected in the UI, but the
    // application only wants the related bodies to be selected, the component
    // node will be removed here.
    if (idsToRem.size > 0) {
        // Remove the items and propagate the change. This will notably trigger
        // the 'selectionArray' event. The associated callback will be entered
        // (or re-entered) immediately.
        if (idsToRem.size == currentSelectionIds.size) {
            hwv.selectionManager.clear(true);
        }
        else {
            const itemsToRem = createSelectionItemsFromNodeIds(idsToRem);
            hwv.selectionManager.remove(itemsToRem, false);
        }
    }

    //
    // Add missing items to the selection
    //
    const itemsToAdd = createSelectionItemsFromNodeIds(idsToAdd);

    if (itemsToAdd.length >= 2) {
        // If there are many items, perform bulk insertion, but suppress
        // triggers, because selection in bulk are not processed correctly
        // anyway.
        hwv.selectionManager.add(itemsToAdd, true);
    }

    if (itemsToAdd.length > 0) {
        // Add a single item, possibly re-adding an item that was just added
        // (effectively a no-op). This will trigger the callbacks and cause
        // the UI to refresh.
        hwv.selectionManager.add(itemsToAdd[0], false);
    }
}

export async function getFaceFromSelectionItem(selectionItem: Communicator.Selection.NodeSelectionItem, hwv: Communicator.WebViewer): Promise<Communicator.Selection.FaceEntity | null> {
    if (selectionItem.isFaceSelection()) {
        return selectionItem.getFaceEntity();
    } else {
        const position = selectionItem.getPosition();
        const screenPosition = position ? Communicator.Point2.fromPoint3(hwv.view.projectPoint(position, hwv.view.getCamera())) : null;

        if (screenPosition) {
            const itemFromPosition = await hwv.view.pickFromPoint(screenPosition, new Communicator.PickConfig(Communicator.SelectionMask.Face));

            return itemFromPosition.getFaceEntity();
        }

        return null;
    }
}

export async function isChannelFace(face: Face, hwv: Communicator.WebViewer): Promise<boolean> {
    const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(face.nodeId, face.faceIndex);
    const allowedFaceTypes = [Communicator.SubentityProperties.FaceType.Cone,
    Communicator.SubentityProperties.FaceType.Cylinder,
    Communicator.SubentityProperties.FaceType.Sphere,
    ];
    const type = props?.type();

    if (type && allowedFaceTypes.includes(type) === true) {
        return true;
    } else {
        const nodeBox = await hwv.model.getNodesBounding([face.nodeId]);
        const meshData = await hwv.model.getNodeMeshData(face.nodeId);
        const vertices = await getFaceVertices(face, meshData);
        const faceBox = new Communicator.Box(vertices[0], vertices[1]);

        vertices.forEach(v => faceBox.addPoint(v));

        const isValidX = faceBox.max.x - faceBox.min.x < 0.3 * (nodeBox.max.x - nodeBox.min.x);
        const isValidY = faceBox.max.y - faceBox.min.y < 0.3 * (nodeBox.max.y - nodeBox.min.y);
        const isValidZ = faceBox.max.z - faceBox.min.z < 0.3 * (nodeBox.max.z - nodeBox.min.z);

        return isValidX && isValidY && isValidZ;
    }
}

export async function detectBafflesFromNodeId(nodeId: number, hwv: Communicator.WebViewer): Promise<Face[][]> {
    const faceInfo = await populateFaceInfoFromNodeId(nodeId, hwv);

    return await detectBaffles(faceInfo);
}

export async function detectBafflesFromFaces(faces: Face[], hwv: Communicator.WebViewer): Promise<Face[][]> {
    const faceInfo = await populateFaceInfoFromFaces(faces, hwv);

    return await detectBaffles(faceInfo);
}

export async function getChannelFromFaceColor(face: Face, hwv: Communicator.WebViewer, getColorFn: GetColorFn): Promise<Face[]> {
    async function populateFaces(nodeIds: number[], color: Communicator.Color): Promise<FaceInfo[]> {
        const meshDataCache = new Map<number, Communicator.MeshDataCopy>();
        const result: Map<string, FaceInfo> = new Map();

        for (const nodeId of nodeIds) {
            let meshData = meshDataCache.get(face.nodeId);
            const faceCount = await hwv.model.getFaceCount(nodeId);

            if (!meshData) {
                meshData = await hwv.model.getNodeMeshData(face.nodeId);
                meshDataCache.set(face.nodeId, meshData);
            }

            for (let i = 0; i < faceCount; i++) {
                const props: Communicator.SubentityProperties.Face | null = await hwv.model.getFaceProperty(nodeId, i);
                const faceColor = await getColorFn(nodeId, i);

                if (props && faceColor?.equals(color)) {
                    const fi = new FaceInfo(nodeId, i, await getFaceVertices({ nodeId, faceIndex: i }, meshData), (props as any).normal, props);

                    if (!result.has(fi.key)) {
                        result.set(fi.key, fi);
                    }
                }
            }
        }

        return [...result.values()];
    }

    const selectedColor = await getColorFn(face.nodeId, face.faceIndex);

    if (!selectedColor) {
        return [];
    }

    const faces = await populateFaces([face.nodeId], selectedColor);
    const connectionMap = buildConnectionMap(faces);
    const startFace = faces.find(f => f.key === `${face.nodeId}-${face.faceIndex}`);

    const isValidFace = (startFace: FaceInfo, face: FaceInfo): boolean => {
        const startFaceVertices = [...startFace.vertices.values()];
        const startFaceBox = new Communicator.Box(startFaceVertices[0], startFaceVertices[1]);
        startFaceVertices.forEach(v => startFaceBox.addPoint(v));

        const faceVertices = [...face.vertices.values()];
        const faceBox = new Communicator.Box(faceVertices[0], faceVertices[1]);
        faceVertices.forEach(v => faceBox.addPoint(v));

        const allowedFaceTypes = [Communicator.SubentityProperties.FaceType.Cone,
        Communicator.SubentityProperties.FaceType.Cylinder,
        Communicator.SubentityProperties.FaceType.Sphere,
        ];

        if (!allowedFaceTypes.includes(face.type)) {
            const isValidX = faceBox.extents().x < 1.3 * startFaceBox.extents().x;
            const isValidY = faceBox.extents().y < 1.3 * startFaceBox.extents().y;
            const isValidZ = faceBox.extents().z < 1.3 * startFaceBox.extents().z;

            return isValidX && isValidY && isValidZ;
        }

        return true;
    }


    if (startFace) {
        let stack: FaceInfo[] = [startFace];
        const result: FaceInfo[] = [];

        while (stack.length) {
            const currentFace = stack.pop();

            if (currentFace) {

                const nextFaces = (connectionMap.get(currentFace) ?? [])
                    .filter(f => isValidFace(startFace, f))
                    .filter(f => result.find(r => r.nodeId === f.nodeId && r.faceIndex === f.faceIndex) === undefined);

                stack = [...stack, ...nextFaces];

                if (!result.find(f => f.nodeId === currentFace.nodeId && f.faceIndex === currentFace.faceIndex)) {
                    result.push(currentFace);
                }
            }
        }

        return result.map(f => {
            return {
                nodeId: f.nodeId,
                faceIndex: f.faceIndex
            }
        });
    }

    return [];
}

async function getFaceVertices(face: Face, md: Communicator.MeshDataCopy): Promise<Communicator.Point3[]> {
    const el = md.faces.element(face.faceIndex);
    const result: Communicator.Point3[] = [];

    const faceIter = el.iterate();

    while (!faceIter.done()) {
        const mesh = faceIter.next();
        result.push(Communicator.Point3.createFromArray(mesh.position));
    }

    return result;
}

async function getPlane(vertices: Communicator.Point3[], hwv: Communicator.WebViewer): Promise<Communicator.Plane> {
    const box = getBoundingBox(vertices);
    const center = box.center();
    const pickConfig = new Communicator.PickConfig(Communicator.SelectionMask.Face);

    pickConfig.respectVisibility = true;
    pickConfig.oneEntityPerTypePerInstance = false;
    pickConfig.enableProximityFaces = true;

    let points: { v: Communicator.Point3, neighbours: number, fromCenter: number }[] = vertices.map(v => {
        const d = Communicator.Point3.distance(v, center);

        return {
            v,
            neighbours: 0,
            fromCenter: d
        }
    });

    points = points.sort((p1, p2) => p2.fromCenter - p1.fromCenter).slice(0, 50);

    for (const point of points) {
        const direction = point.v.copy().subtract(center);
        const ray = new Communicator.Ray(center, direction);
        const selectionKeys = (await hwv.view.pickAllFromRay(ray, pickConfig)).map(s => ({
            nodeId: s.getNodeId(),
            faceIndex: s.getFaceEntity()?.getCadFaceIndex()
        })).filter(f => f.faceIndex !== undefined).map(f => makeFaceKey(f as Face));
        const selectionKeysSet = new Set(selectionKeys);

        point.neighbours = selectionKeysSet.size;
    }

    points = points.sort((p1, p2) => p1.neighbours - p2.neighbours);

    let minEdgeLimit = 1;

    for (const point of points) {
        const hasMoreThenOnePoint = points.filter(p => p.neighbours > 0 && p.neighbours === point.neighbours).length > 2;

        if (hasMoreThenOnePoint) {
            minEdgeLimit = point.neighbours;
            break;
        }
    }

    points = points.filter(p => p.neighbours === minEdgeLimit);
    points = points.sort((p1, p2) => p2.fromCenter - p1.fromCenter);

    let planes: { plane: Communicator.Plane, count: number }[] = [];

    const vs = points.map(p => p.v);

    for (const point of vs) {
        const neighbours = vs.filter(p => p.equals(point) === false)
            .sort((p1, p2) => Communicator.Point3.distance(point, p1) - Communicator.Point3.distance(point, p2)).slice(0, 2);


        const plane = Communicator.Plane.createFromPoints(point, neighbours[0], neighbours[1]);
        const initalPlanePoints = [point, ...neighbours];
        const otherPoints = points.filter(p => initalPlanePoints.includes(p.v) === false);
        const count = otherPoints.filter(p => isPointOnPlane(p.v, plane) === true).length;

        planes.push({ plane, count: count + initalPlanePoints.length });
    }

    planes = planes.sort((p1, p2) => p2.count - p1.count);

    return planes.length ? planes[0].plane : new Communicator.Plane();
}

export type SyntheticMeshInfo = {
    center: number[], //extra info for indicator and comparisons 
    vertexes: number[] //already meshed (uses center)
}

export async function createMesh(vertices: Communicator.Point3[], hwv: Communicator.WebViewer): Promise<SyntheticMeshInfo> {
    const box = new Communicator.Box(vertices[0], vertices[1]);
    vertices.slice(2).forEach(v => box.addPoint(v));

    const center = box.center();

    const plane = new Communicator.Plane();
    plane.setFromPoints(vertices[0], vertices[1], vertices[2]);

    // Trying to arrange vertices clockwise
    const upPlane = new Communicator.Plane();
    const upVector = center.copy().add(hwv.view.getCamera().getUp().scale(5));
    upPlane.setFromPoints(upVector, plane.normal, center);

    const rightSideVertices = vertices
        .filter(v => upPlane.determineSide(v) === false);

    const sortedRightSideVertices = rightSideVertices.sort((v1, v2) => Communicator.Point3.distance(v1, upVector) - Communicator.Point3.distance(v2, upVector));

    const edges: Communicator.Point3[][] = [[sortedRightSideVertices[0], sortedRightSideVertices[1]]];

    if (sortedRightSideVertices.length < 2) {
        const leftSideVertices = vertices
            .filter(v => upPlane.determineSide(v) === true);
        const sortedLeftSideVertices = leftSideVertices.sort((v1, v2) => Communicator.Point3.distance(v2, upVector) - Communicator.Point3.distance(v1, upVector));

        edges[0][1] = sortedLeftSideVertices[0];
    }

    while (edges.length < vertices.length - 1) {

        const startPoint = edges[edges.length - 1][1];
        const processed = edges.flat();

        const sorted = vertices
            .filter(v => processed.find(p => p.equals(v)) === undefined)
            .sort((v1, v2) => Communicator.Point3.distance(v1, startPoint) - Communicator.Point3.distance(v2, startPoint))

        if (!sorted.length) {
            break;
        }

        const endPoint = sorted[0];
        edges.push([startPoint, endPoint]);
    }

    edges.push([edges[edges.length - 1][1], edges[0][0]]);


    //will be using this array, along with the center point to save the info.
    let vertexData: number[] = [];
    const v3 = [center.x, center.y, center.z];
    for (const points of edges) {
        for (let i = 0; i < points.length - 1; i++) {
            const v1 = [points[i].x, points[i].y, points[i].z];
            const v2 = [points[i + 1].x, points[i + 1].y, points[i + 1].z];

            vertexData = [...vertexData, ...v1, ...v2, ...v3];
        }
    }

    return { center: v3, vertexes: vertexData };
}

export async function drawSyntheticNode(vertexData: number[], center: number[], color: Communicator.Color, hwv: Communicator.WebViewer | null) {
    const meshData = new Communicator.MeshData();
    const extractPolyline = (center: number[], vertexData: number[]): number[] => {
        const centerPoint = makePointKey(Communicator.Point3.createFromArray(center));
        const edgeVertexData = new Set<string>();

        for (let i = 0; i < vertexData.length - 3; i += 3) {
            const vertice = makePointKey(new Communicator.Point3(vertexData[i], vertexData[i + 1], vertexData[i + 2]));
            edgeVertexData.add(vertice);
        }
        edgeVertexData.delete(centerPoint);

        let polyline: number[] = [];

        for (const ev of edgeVertexData.values()) {
            const vertice = parsePointKey(ev);
            polyline = [...polyline, vertice.x, vertice.y, vertice.z];
        }

        polyline = [...polyline, polyline[0], polyline[1], polyline[2]];

        return polyline;
    }

    meshData.setBackfacesEnabled(true);
    meshData.addFaces(vertexData);
    meshData.addPolyline(extractPolyline(center, vertexData));

    const meshId = await hwv?.model.createMesh(meshData)!;
    const meshInstanceData = new Communicator.MeshInstanceData(meshId);
    meshInstanceData.setFaceColor(color);
    meshInstanceData.setLineColor(color);

    const nodeId = await hwv?.model.createMeshInstance(meshInstanceData)!;

    return { nodeId, meshId };
}

function arrangeVertices(vertices: Communicator.Point3[]): Communicator.Point3[] {
    const uniqueVertices = getUniquePoints(vertices);
    const box = getBoundingBox(uniqueVertices);
    const orderedPoints: Communicator.Point3[] = [uniqueVertices[0]];
    const c1 = uniqueVertices[0].copy().subtract(box.center());
    const c2 = uniqueVertices[1].copy().subtract(box.center());
    const normalToSurface = Communicator.Point3.cross(c1, c2).negate().add(box.center());

    while (orderedPoints.length < uniqueVertices.length) {
        const currentPoint = orderedPoints[orderedPoints.length - 1];
        const plane = Communicator.Plane.createFromPoints(currentPoint, box.center(), normalToSurface);
        const rightSidePoints = uniqueVertices.filter(v => plane.determineSide(v) === true).sort((v1, v2) => plane.distanceToPoint(v1) - plane.distanceToPoint(v2));
        let nextPointDetected: boolean = false;

        for (let i = 0; i < rightSidePoints.length; i++) {
            const nextRightPoint = rightSidePoints[i];
            const newPlane = Communicator.Plane.createFromPoints(nextRightPoint, box.center(), normalToSurface);
            const updatedRightSidePoints = rightSidePoints.filter(v => newPlane.determineSide(v) === true);

            if (rightSidePoints.length - updatedRightSidePoints.length === 1) {
                orderedPoints.push(nextRightPoint);
                nextPointDetected = true;
                break;
            }
        }

        if (!nextPointDetected) {
            console.warn('Unable to arrange points');
            return [];
        }
    }

    return orderedPoints;
}

export function drawFace(vertices: Communicator.Point3[], hwv?: Communicator.WebViewer): SyntheticMeshInfo | null {
    const orderedPoints = arrangeVertices(vertices);

    if (!orderedPoints.length) {
        console.warn('Unable to generate surface');
        return null;
    }
    const box = getBoundingBox(orderedPoints);
    const v3 = [box.center().x, box.center().y, box.center().z];
    const startPoint = orderedPoints[orderedPoints.length - 1];
    const endPoint = orderedPoints[0];
    let vertexData: number[] = [];

    for (let i = 0; i < orderedPoints.length - 1; i++) {
        const v1 = [orderedPoints[i].x, orderedPoints[i].y, orderedPoints[i].z];
        const v2 = [orderedPoints[i + 1].x, orderedPoints[i + 1].y, orderedPoints[i + 1].z];

        vertexData = [...vertexData, ...v1, ...v2, ...v3];
    }

    vertexData = [...vertexData, ...[startPoint.x, startPoint.y, startPoint.z], ...[endPoint.x, endPoint.y, endPoint.z], ...v3];

    return { center: v3, vertexes: vertexData };
}

export function createRectangleMeshData(center: Communicator.Point3, sizeOfRectangle: { x: number, y: number, z: number }): {
    positions: number[], normals: number[], uvs: number[]
} {
    const p0 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p1 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p2 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p3 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z - sizeOfRectangle.z / 2);
    const p4 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);
    const p5 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y + sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);
    const p6 = new Communicator.Point3(center.x + sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);
    const p7 = new Communicator.Point3(center.x - sizeOfRectangle.x / 2, center.y - sizeOfRectangle.y / 2, center.z + sizeOfRectangle.z / 2);

    const positions = [
        p0.x, p0.y, p0.z, p2.x, p2.y, p2.z, p1.x, p1.y, p1.z, p0.x, p0.y, p0.z, p3.x, p3.y, p3.z, p2.x, p2.y, p2.z,
        p2.x, p2.y, p2.z, p3.x, p3.y, p3.z, p4.x, p4.y, p4.z, p2.x, p2.y, p2.z, p4.x, p4.y, p4.z, p5.x, p5.y, p5.z,
        p1.x, p1.y, p1.z, p2.x, p2.y, p2.z, p5.x, p5.y, p5.z, p1.x, p1.y, p1.z, p5.x, p5.y, p5.z, p6.x, p6.y, p6.z,
        p0.x, p0.y, p0.z, p7.x, p7.y, p7.z, p4.x, p4.y, p4.z, p0.x, p0.y, p0.z, p4.x, p4.y, p4.z, p3.x, p3.y, p3.z,
        p5.x, p5.y, p5.z, p4.x, p4.y, p4.z, p7.x, p7.y, p7.z, p5.x, p5.y, p5.z, p7.x, p7.y, p7.z, p6.x, p6.y, p6.z,
        p0.x, p0.y, p0.z, p6.x, p6.y, p6.z, p7.x, p7.y, p7.z, p0.x, p0.y, p0.z, p1.x, p1.y, p1.z, p6.x, p6.y, p6.z
    ];
    const uvs = [
        0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0,
        0, 0, 1, 1, 1, 0, 0, 0, 0, 1, 1, 1,
    ];
    const normals = [
        0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1,
        0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0,
        1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0,
        -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0,
        0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1,
        0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0,
    ];
    return {
        positions, normals, uvs
    };
}

export async function createRectangularMoldPartsAroundModel(hwv: Communicator.WebViewer, size: MoldSize, center: Coordinates) {
    let sizeAsPoint3 = new Communicator.Point3(
        size.x / 2,
        size.y,
        size.z
    );
    const center1 = new Communicator.Point3(center.x - size.x / 4, center.y, center.z);
    const center2 = new Communicator.Point3(center.x + size.x / 4, center.y, center.z);
    const nodeId1 = await createRectangularMoldPart(hwv, center1, sizeAsPoint3, moldGroup1Color);
    const nodeId2 = await createRectangularMoldPart(hwv, center2, sizeAsPoint3, moldGroup1Color);
    hwv.model.setNodesOpacity([nodeId1, nodeId2], 0.5);
    return [nodeId1, nodeId2];
}

export async function computeInitialBasicMoldConfig(hwv: Communicator.WebViewer, plasticPart: Part[]) {
    let boundingBox;
    if (plasticPart.length > 0) {
        const nodesIds = plasticPart.flatMap(p => p.nodesIds);
        boundingBox = await hwv.model.getNodesBounding(nodesIds);
    } else {
        boundingBox = await hwv.model.getModelBounding(true, false);
    }
    const fullMoldInitialSize = computeBasicMoldInitialSize(boundingBox);
    const center = boundingBox.center();
    return { size: { x: fullMoldInitialSize.x, y: fullMoldInitialSize.y, z: fullMoldInitialSize.z }, center: { x: center.x, y: center.y, z: center.z } };
}

export function computeBasicMoldInitialSize(boundingBox: Communicator.Box) {
    const extents = boundingBox.extents();
    const longestExtends = Math.max(extents.x, extents.y, extents.z);
    const factor = 0.5;
    return {
        x: parseFloat((extents.x + factor * longestExtends).toFixed(2)),
        y: parseFloat((extents.y + factor * longestExtends).toFixed(2)),
        z: parseFloat((extents.z + factor * longestExtends).toFixed(2))
    }
}

export async function createRectangularMoldPart(hwv: Communicator.WebViewer, center: Communicator.Point3, size: Communicator.Point3, color: Communicator.Color) {
    const meshData = new Communicator.MeshData();
    const rectangleMeshData = createRectangleMeshData(center, {
        x: size.x,
        y: size.y,
        z: size.z
    });
    meshData.addFaces(rectangleMeshData.positions, rectangleMeshData.normals, undefined, rectangleMeshData.uvs);
    meshData.setFaceWinding(Communicator.FaceWinding.CounterClockwise);
    const meshId = await hwv.model.createMesh(meshData);
    const meshInstanceData = new Communicator.MeshInstanceData(
        meshId,
        new Communicator.Matrix(),
        "texture-cube",
        color
    );
    return await hwv.model.createMeshInstance(meshInstanceData);
}

export function drawPoint(point: Communicator.Point3, text: string, hwv: Communicator.WebViewer, color: Communicator.Color = Communicator.Color.green()) {
    const markupItem = new DebugMarkupItem(hwv, point, text, color);
    markupIds.push(hwv.markupManager.registerMarkup(markupItem));
}

export function drawLine(startPoint: Communicator.Point3, endPoint: Communicator.Point3, hwv: Communicator.WebViewer, color: Communicator.Color = Communicator.Color.green()) {
    const markupItem = new DebugLineMarkupItem(hwv, startPoint, endPoint, color);
    markupIds.push(hwv.markupManager.registerMarkup(markupItem));
}

export function clearPoints(hwv: Communicator.WebViewer) {
    markupIds.forEach(id => hwv.markupManager.unregisterMarkup(id));
    markupIds = [];
}

export function makeFaceKey(face: { nodeId: number, faceIndex: number }): string {
    return `${face.nodeId}-${face.faceIndex}`;
}

const faceKeyRegexp = new RegExp(/([0-9\-]+)\-([0-9]+)/);

export function parseFaceKey(key: string): Face {
    const parts = key.match(faceKeyRegexp);

    if (!parts) {
        throw new Error('Wrong face key format');
    }

    const nodeId = parseInt(parts[1]);
    const faceIndex = parseInt(parts[2]);

    return {
        nodeId,
        faceIndex
    }
}

export function makePointKey(point: Communicator.Point3): string {
    return JSON.stringify(point.toJson());
}

export function parsePointKey(key: string): Communicator.Point3 {
    return Communicator.Point3.fromJson(JSON.parse(key));
}

export function areSyntheticMeshesEqual(mesh1: SyntheticMeshInfo, mesh2: SyntheticMeshInfo): boolean {
    const vertexSet1 = new Set<number>(mesh1.vertexes);
    const vertexSet2 = new Set<number>(mesh2.vertexes);

    return Communicator.Util.setSubtraction(vertexSet1, vertexSet2).size === 0;
}

export const getCadFileNameProperty = async (nodeId: SessionNodeId, model: Communicator.Model) => {
    const properties = await model.getNodeProperties(nodeId);
    const cadFileName = properties && properties["cadFileName"] ? properties["cadFileName"] : null;
    return cadFileName;
}

export async function getFaceBoundingBox(face: Face, hwv: Communicator.WebViewer): Promise<Communicator.Box> {
    const meshData = await hwv.model.getNodeMeshData(face.nodeId);
    const vertices = await getFaceVertices(face, meshData);
    const box = new Communicator.Box(vertices[0], vertices[1]);

    vertices.slice(2).forEach(v => box.addPoint(v));

    return box;
}

async function getNodeVolume(nodeId: SessionNodeId, model: Communicator.Model): Promise<number> {
    // Get the "Volume" property, stored as a string (e.g. "1.23mm3"). The property is defined for a bodies
    // and not for other node types. If the body is not closed, the volume has a negative value.
    const properties = await model.getNodeProperties(nodeId);
    const volumeStr = properties?.["Volume"] ?? "";
    const volume = parseFloat(volumeStr);
    return Number.isNaN(volume) ? 0.0 : volume;
}

async function isSheetBody(nodeId: SessionNodeId, model: Communicator.Model): Promise<boolean> {
    // A sheet body is a regular body that is non-closed.
    const nodeType = model.getNodeType(nodeId);
    if (nodeType !== Communicator.NodeType.BodyInstance) {
        return false;
    }
    const volume = await getNodeVolume(nodeId, model);
    return volume < 0.0;
}


//
// Node helpers
//

function getAllLeafNodeIds(seedIds: SessionNodeId[], model: Communicator.Model): SessionNodeId[] {
    const leafIds = [];
    const queue = [...seedIds];
    for (const nodeId of queue) {
        const childIds = model.getNodeChildren(nodeId);
        if (childIds.length === 0) {
            leafIds.push(nodeId)
        } else {
            queue.push(...childIds);
        }
    }
    return [...new Set(leafIds)];
}

export function getAllDescendantNodeIds(seedIds: SessionNodeId[], model: Communicator.Model, includeSeed: boolean): SessionNodeId[] {
    // Get the list of all descendant node ids from a list of seeds node, including or excluding the seed nodes.
    const descendants: SessionNodeId[] = includeSeed ? [...seedIds] : [];
    const queue = [...seedIds];

    while (queue.length) {
        const currentNodeChildrenIds = model.getNodeChildren(queue.pop()!);
        descendants.push(...currentNodeChildrenIds);
        queue.push(...currentNodeChildrenIds);
    }
    return [...new Set(descendants)];
}

export function getAllDominatorNodeIds(seedIds: SessionNodeId[], model: Communicator.Model, ascend: boolean = false): SessionNodeId[] {
    // A dominator is the highest-level ancestor on a branch of a given set of candidate nodes.
    const dominatorIds = [];
    let relatedNodeIds;
    if (ascend)
        relatedNodeIds = new Set<SessionNodeId>(getAllAscendantAndDescendantNodeIds(seedIds, model));
    else {
        relatedNodeIds = new Set<SessionNodeId>(getAllDescendantNodeIds(seedIds, model, true));
    }
    for (const nodeId of relatedNodeIds) {
        const parentNodeId = model.getNodeParent(nodeId);
        if (parentNodeId === null) {
            // This node is the absolute root node or a detached node.
            dominatorIds.push(nodeId);
        }
        else if (relatedNodeIds.has(parentNodeId) === false) {
            dominatorIds.push(nodeId);
        }
    }
    return dominatorIds;
}

export function getAllAscendantAndDescendantNodeIds(seedIds: SessionNodeId[], model: Communicator.Model): SessionNodeId[] {
    // Walk down: Get all the nodes that descend from the seeds (including the seeds).
    const nodeIdSet = new Set<SessionNodeId>(getAllDescendantNodeIds(seedIds, model, true));
    while (true) {
        // Walk up: Add the dominator nodes.
        const nodeIds = Array.from(nodeIdSet);
        for (const nodeId of nodeIds) {
            const parentNodeId = model.getNodeParent(nodeId);
            // If all siblings nodes at a given level are included, but not the parent,
            // we can include the parent unambiguously.
            if (parentNodeId !== null && nodeIdSet.has(parentNodeId) === false) {
                const childIds = model.getNodeChildren(parentNodeId);
                if (childIds.every(id => nodeIdSet.has(id))) {
                    nodeIdSet.add(parentNodeId);
                }
            }
        }
        if (nodeIds.length === nodeIdSet.size) {
            return nodeIds;
        }
    }
}


//
// Body helpers
//

export function getDescendantBodyIds(nodeId: SessionNodeId, model: Communicator.Model): number[] {
    // Get the list of all descendant body node ids. Bodies are always leaf nodes.
    const bodyIds = [];
    const nodeType = model.getNodeType(nodeId);
    switch (nodeType) {
        case Communicator.NodeType.BodyInstance:
            bodyIds.push(nodeId);
            break;
        case Communicator.NodeType.Part:
        case Communicator.NodeType.PartInstance:
        case Communicator.NodeType.AssemblyNode:
            const childIds = model.getNodeChildren(nodeId);
            for (const childId of childIds) {
                bodyIds.push(...getDescendantBodyIds(childId, model));
            }
            break;
    }
    return bodyIds;
}

export function getAllDescendantBodyIds(nodeIds: number[], model: Communicator.Model): number[] {
    const bodyIds = [];
    for (const nodeId of nodeIds) {
        const descendantBodyIds = getDescendantBodyIds(nodeId, model)
        bodyIds.push(...descendantBodyIds)
    }
    return [...new Set(bodyIds)];
}

export async function getAllDescendantSolidNodesIds(nodeIds: SessionNodeId[], model: Communicator.Model): Promise<SessionNodeId[]> {
    const solidIds = [];

    // Simplify the node list, remove redundant nodes by selecting the dominators.
    const dominators = getAllDominatorNodeIds(nodeIds, model, false);

    // "Solid" nodes are nodes that form closed (watertight) volumes.
    // In most cases, these are simply the leaf bodies of the subtree,
    // but there are exceptions.
    for (const nodeId of dominators) {
        const leafIds = getAllLeafNodeIds([nodeId], model);
        const promiseArray = leafIds.map(leafId => isSheetBody(leafId, model));
        const isSheetBodyArray = await Promise.all(promiseArray);
        const hasAnySheetBody = isSheetBodyArray.includes(true);
        if (hasAnySheetBody) {
            // We found some "bodies" under the component that are not closed.
            // Treat them as faces, and treat the ancestral component (the user original
            // selection) as the "body". The assumption is that collectively, these
            // disjointed faces form a closed solid. We do not verify this assumption.
            // This is a non-standard CAD. Use the user selection as-is.
            solidIds.push(nodeId);
        } else {
            // Some CADs have non-geometric components (components without any leaf bodies).
            // These components are empty leaf nodes. Remove them to avoid needlessly
            // sending empty object files to the solver.
            const bodyIds = leafIds.filter(leafId => model.getNodeType(leafId) === Communicator.NodeType.BodyInstance);
            solidIds.push(...bodyIds);
        }
    }

    if (solidIds.length === 0) {
        // This set of nodes has no real geometry. We (probably) don't want to send an empty
        // selection to the solver, so send a selection with the empty nodes instead. This could
        // be revisited.
        solidIds.push(...dominators);
    }

    // Stabilize unit test.
    return solidIds.sort();
}


//
// Box helpers
//

export function getPlaneAreaBox(vertices: Communicator.Point3[]): Communicator.Box | null {
    if (vertices.length < 3) {
        return null;
    }

    const originBox = getBoundingBox(vertices);
    const plane = Communicator.Plane.createFromPoints(originBox.center(), vertices[0], vertices[1]);

    if (isNaN(plane.d)) {
        return null;
    }

    const mainAxis = Math.max(Math.abs(plane.normal.x), Math.abs(plane.normal.y), Math.abs(plane.normal.z));
    const normal = new Communicator.Point3(
        Math.abs(plane.normal.x) === mainAxis ? 1 : 0,
        Math.abs(plane.normal.y) === mainAxis ? 1 : 0,
        Math.abs(plane.normal.z) === mainAxis ? 1 : 0
    );
    const project = (p: Communicator.Point3) => {
        const d = Communicator.Point3.dot(normal.copy(), p);
        const dn = normal.copy().scale(d);
        return p.copy().subtract(dn);
    };
    const projectedVertices = vertices.map(project);

    return getBoundingBox(projectedVertices);
}
export interface OperatorIndex {
    indexOf: number,
    operatorId: Communicator.OperatorId | null
}

export function getCurrentSelectionOperator(hwv: Communicator.WebViewer): OperatorIndex | null {
    //select
    const selectOperator = Communicator.OperatorId.Select;
    //area select
    const areaSelectOperator = Communicator.OperatorId.AreaSelect;
    //Measure edges
    const measureEdgeOperator = Communicator.OperatorId.MeasureEdgeLength;
    //Measure point to point
    const measurePointToPointOperator = Communicator.OperatorId.MeasurePointPointDistance;
    //Measure angle between faces
    const measureAngleBetweenFacesOperator = Communicator.OperatorId.MeasureFaceFaceAngle;
    //Measure distance between faces
    const measureDistanceBetweenFacesOperator = Communicator.OperatorId.MeasureFaceFaceDistance;

    const availableOperators = [selectOperator, areaSelectOperator, measureEdgeOperator, measurePointToPointOperator, measureAngleBetweenFacesOperator, measureDistanceBetweenFacesOperator];

    for (const operatorId of availableOperators) {
        const indexOf = hwv.operatorManager.indexOf(operatorId);
        if (indexOf != -1) {
            return {
                indexOf: indexOf,
                operatorId: operatorId
            }
        }
    }

    return null;
}

export async function doPartsIntersect(part1: Part, part2: Part, model: Communicator.Model): Promise<boolean> {
    const bb1 = await model.getNodesBounding(part1.nodesIds);
    const bb2 = await model.getNodesBounding(part2.nodesIds);
    const doBoundingBoxesIntersect = (box1: Communicator.Box, box2: Communicator.Box): boolean => {
        if (box1.max.x < box2.min.x || box1.min.x > box2.max.x) {
            return false;
        }
        if (box1.max.y < box2.min.y || box1.min.y > box2.max.y) {
            return false;
        }
        if (box1.max.z < box2.min.z || box1.min.z > box2.max.z) {
            return false;
        }
        return true;
    }

    if (doBoundingBoxesIntersect(bb1, bb2)) { 
        const distance = await model.computeMinimumBodyBodyDistance(part1.nodesIds[0], part2.nodesIds[0]);

        return distance.distance < 0.1; 
    }
    return false;
}

export function fixOpenEdges(vertices: number[]): number[] {
    const points: Communicator.Point3[] = []; 
            
    for (let i = 0; i < vertices.length; i += 3) {
        points.push(new Communicator.Point3(vertices[i], vertices[i + 1], vertices[i + 2]));
    }

    const polygons: Map<number, string[]> = new Map();
    const polygonVertices: Map<string, number[]> = new Map();
    const pointsString = points.map(makePointKey);

    for (let i = 0; i < pointsString.length; i += 3) {
        const polygon = pointsString.slice(i, i + 3);
        polygons.set(i, polygon);

        for (const vertex of polygon) {
            if (!polygonVertices.has(vertex)) {
                polygonVertices.set(vertex, []);
            }

            polygonVertices.get(vertex)!.push(i);
        }
    }

    for (const [pointKey, polyIndices] of polygonVertices) {
        if (polyIndices.length == 1) {
            const polygonWithOpenEdge = polygons.get(polyIndices[0])!; 
            const openEdgeVertex = parsePointKey(pointKey);
            const neighbourVertices = polygonWithOpenEdge.map(key => polygonVertices.get(key)!).flat().map(polyIndex => polygons.get(polyIndex)!).flat();
            const replacementVertex = neighbourVertices.filter(v => polygonWithOpenEdge.includes(v) === false).map(parsePointKey)
                .sort((p1, p2) => Communicator.Point3.distance(p1, openEdgeVertex) - Communicator.Point3.distance(p2, openEdgeVertex))[0];
            const openEdgeVertexIndex = polygonWithOpenEdge.indexOf(pointKey);

            polygonWithOpenEdge[openEdgeVertexIndex] = makePointKey(replacementVertex);
        }
    }

    const newVertices: number[] = [];

    for (const polygon of polygons.values()) {
        for (const vertex of polygon) {
            const point = parsePointKey(vertex);

            newVertices.push(point.x);
            newVertices.push(point.y);
            newVertices.push(point.z);
        }
    }
    
    return newVertices;
}
export type MeshDataCopyElementIterable = Communicator.MeshDataCopyElement & Iterable<Communicator.MeshDataCopyVertex>;

export type MeshDataCopyElementGroupIterable = Communicator.MeshDataCopyElementGroup & Iterable<Communicator.MeshDataCopyVertex>;