import { TreeviewItemModel } from '../components/job/TreeviewNodeModel';
import JobData, { Face, Part, Tree } from '../store/job/job-data'

function getAncestorTreeItems(treeItem: TreeviewItemModel, tree: Tree): TreeviewItemModel[] {
    const prefix = treeItem.path;
    const treeItems = Object.values(tree).filter(item => prefix.startsWith(item.path));
    return treeItems.filter(item => item !== treeItem);
}

function getDescendantTreeItems(treeItem: TreeviewItemModel, tree: Tree): TreeviewItemModel[] {
    const prefix = treeItem.path;
    const treeItems = Object.values(tree).filter(item => item.path.startsWith(prefix));
    return treeItems.filter(item => item !== treeItem);
}

function getTreeItemDescendantNodeIds(path: string, tree: Tree): number[] {
    let treeItem = findTreeItemByPath(path, tree);
    let nodeIds = treeItem?.nodeIds || [];
    if (treeItem) {
        for (const descendantItem of getDescendantTreeItems(treeItem, tree)) {
            nodeIds = [...nodeIds, ...descendantItem.nodeIds];
        }
    }
    return [... new Set(nodeIds)];
}

export function findTreeItemByNodeId(nodeId: number, tree: Tree): TreeviewItemModel | null {
    return Object.values(tree).find(item => item.nodeIds.includes(nodeId)) ?? null;
}

export function findAllTreeItemsByNodeIds(nodeIds: Set<number>, tree: Tree): TreeviewItemModel[] {
    return Object.values(tree).filter(item => item.nodeIds.some(nodeId => nodeIds.has(nodeId)));
}

export function findTreeItemByPath(path: string, tree: Tree): TreeviewItemModel | null {
    // Normal fast path.
    const treeItem = tree[path];
    if (treeItem) {
        return treeItem;
    }
    // Apparently slashes are used inconsistently in some legacy data. Ignore bookend path separators.
    if (!path) return null;
    const removeSlashes = (path: string) => { return path.replace(/^\/|\/$/g, ''); }
    path = removeSlashes(path);
    return Object.values(tree).find(item => path === removeSlashes(item.path)) ?? null;
}

function getShallowestTreeItem(treeItems: TreeviewItemModel[]): TreeviewItemModel | null {
    // Returns the treeItem nearest to the root from the given list. The method is
    // intended to operate on a branch/subtree, therefore we can look at the path length
    // as a proxy to the depth (instead of counting path separators).
    if (treeItems.length === 0) {
        return null;
    }
    return treeItems.reduce(
        (prevItem, item) => (item.path.length < prevItem.path.length ? item : prevItem),
        treeItems[0]);
}

export function findCadTopItem(nodeId: number, jobContext: JobData): TreeviewItemModel | null {
    // Find the treeItem where the model associated with nodeId was inserted.
    let treeItem = findTreeItemByNodeId(nodeId, jobContext.Tree) ?? null;
    if (!treeItem) {
        return null;
    }
    const cadFileName = treeItem.cadFileName;
    if (!cadFileName) {
        return null;
    }
    const ancestors = getAncestorTreeItems(treeItem, jobContext.Tree);
    const ancestorsSameCad = ancestors.filter(item => item.cadFileName === cadFileName);
    return getShallowestTreeItem(ancestorsSameCad);
}

export function getAnyCadModelTopTreeItem(tree: Tree): TreeviewItemModel | null {
    let treeItems = Object.values(tree);
    let topTreeItem: TreeviewItemModel | null;
    while (topTreeItem = getShallowestTreeItem(treeItems)) {
        // Looking for a cad model tree item near the top of the tree.
        // The treeItem for the absolute root node will not have a cadFileName. 
        if (topTreeItem.cadFileName) {
            return topTreeItem;
        }
        treeItems = treeItems.filter(item => item !== topTreeItem);
    }
    return null;
}

export function GetAllNodeIds(jobContext: JobData): number[] {
    return Object.values(jobContext.Tree).map(v => v.nodeIds).flat()
}

export function getTreeItemsNodeIds(treeItems: TreeviewItemModel[]): number[] {
    const nodeIds = treeItems.flatMap(treeItem => treeItem.nodeIds);
    return nodeIds;
}

export function setPartVisibility(parts: Part[], visibilty: boolean, jobContext: JobData, hwv: Communicator.WebViewer): void {
    const nodeIds = parts.flatMap(p => getTreeItemDescendantNodeIds(p.id, jobContext.Tree));
    const treeItems = parts.map(p => jobContext.Tree[p.id]).filter(item => item);

    treeItems.forEach(item => item.isVisible = visibilty);
    hwv.model.setNodesVisibility(nodeIds, visibilty);
}

export async function GetSceneVisibilityMap(model: Communicator.Model, jobContext: JobData)
    : Promise<Map<number, boolean>> {
    const rootNodeId = model.getAbsoluteRootNode();
    const visiblityState = await model.getVisibilityState(rootNodeId);
    const allNodeIds = GetAllNodeIds(jobContext);
    const visibilityMap = new Map<number, boolean>();

    allNodeIds.forEach(nodeId => {
        visibilityMap.set(nodeId,
            visiblityState.visibilityExceptions.has(nodeId)
                ? !visiblityState.defaultVisibility : visiblityState.defaultVisibility);
    });

    if (jobContext.basicMold.enabled) {
        jobContext.basicMold.nodesIds.forEach(n => {
            visibilityMap.set(n, model.getNodeVisibility(n));
        });
    }
    return visibilityMap;
}

export function getChannelName(channelId: number) {
    return `Channel ${channelId + 1}`;
}

export function getChannelId(channelId: number) {
    return `channel-${channelId}`;
}

export function getBasicMoldPart(nodesIds: number[]): Part {
    return { id: '/basic-mold', name: 'Basic mold', path: '/basic-mold', cadFileName: '', nodesIds };
}

export const GetSelectedWithExchangeId = (selected: Face[], hwv: Communicator.WebViewer): Face[] => {
    const result: Face[] = [];

    for (const s of selected) {
        const exchangeId = hwv.model.getNodeExchangeId(s.nodeId);

        if (exchangeId === null) {
            const childrenList = hwv.model.getNodeChildren(s.nodeId);

            for (const childId of childrenList) {
                result.push({
                    nodeId: childId,
                    faceIndex: 0
                })
            }

        } else {
            result.push(s);
        }
    }

    return result;
}

